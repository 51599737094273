<template>
  <section class="flex items-center justify-center">
    <div
      class="
        aspect-[16/9]
        bg-white
        container
        flex flex-col
        m-20
        rounded-2xl
        shadow-2xl shadow-WALIGHTBLUE-200
        w-[800px]
        md:flex-row
      "
    >
      <div class="pl-8 px-8 py-4 w-full">
        <img src="/login-pic.svg" class="h-full" />
      </div>
      <div class="flex flex-col items-center justify-center p-8 w-full">
        <div class="mb-14">
          <img src="/wa-logo.png" class="w-48" />
        </div>
        <div class="mb-4">
          <h4
            class="font-bold text-WADARKBLUE-500 text-center text-xs uppercase"
          >
            {{ $t("sign-in-title") }}
          </h4>
        </div>
        <form class="flex flex-col items-center" @submit.prevent="onSubmit">
          <div
            v-if="error"
            class="py-2 px-6 bg-red-100 rounded-lg my-2 text-sm w-full"
          >
            <span class="text-red-400">
              {{ error }}
            </span>
          </div>
          <div class="mb-4 relative">
            <input
              :placeholder="$t('email')"
              type="email"
              class="input-field outline-none p-2 pl-10 text-sm w-64"
              v-model="email"
            />
            <div
              class="
                absolute
                flex
                inset-y-0
                items-center
                left-0
                pointer-events-none
                px-2
              "
            >
              <img src="/icon-login-email.svg" class="w-3" />
            </div>
          </div>
          <div class="mb-4 relative">
            <input
              type="password"
              :placeholder="$t('password')"
              class="input-field outline-none p-2 pl-10 text-sm w-64"
              v-model="password"
            />
            <div
              class="
                absolute
                flex
                inset-y-0
                items-center
                left-0
                pointer-events-none
                px-2
              "
            >
              <img src="/icon-login-password.svg" class="w-3" />
            </div>
          </div>
          <div class="mt-2">
            <button
              class="
                bg-WADARKBLUE-500
                hover:bg-WADARKBLUE-400
                p-3
                rounded-full
                text-sm text-white
                w-64
                uppercase
              "
            >
              {{ $t("sign-in") }}
            </button>
          </div>
        </form>
        <div class="mt-4">
          <router-link
            to="/password/forgotpassword"
            class="hover:text-WADARKBLUE-500 mt-3 text-WAORANGE-500 text-sm"
            >{{ $t("forgot-password") }}</router-link
          >
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  name: "Login",
  data() {
    return {
      email: "",
      password: "",
    };
  },
  methods: {
    async onSubmit() {
      const user = await this.login({
        email: this.email,
        password: this.password,
      });

      if (user) window.location = "/";
    },
    ...mapActions(["login"]),
  },

  computed: {
    ...mapGetters(["loading", "error"]),
  },
};
</script>

<style scoped>
section {
  min-height: 85vh;
}
</style>
